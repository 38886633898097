import React from 'react';
import PropTypes from 'prop-types';

export function StarIcon({ primary, secondary }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="icon-star">
      <circle cx="12" cy="12" r="10" fill={primary} />
      <path
        fill={secondary}
        d="M9.53 16.93a1 1 0 0 1-1.45-1.05l.47-2.76-2-1.95a1 1 0 0 1 .55-1.7l2.77-.4 1.23-2.51a1 1 0 0 1 1.8 0l1.23 2.5 2.77.4a1 1 0 0 1 .55 1.71l-2 1.95.47 2.76a1 1 0 0 1-1.45 1.05L12 15.63l-2.47 1.3z"
      />
    </svg>
  );
}

StarIcon.propTypes = {
  primary: PropTypes.string.isRequired,
  secondary: PropTypes.string.isRequired
};

export function DownloadIcon({ primary, secondary }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="icon-cloud-download">
      <path
        fill={primary}
        d="M15 15v-3a3 3 0 0 0-6 0v3H6a4 4 0 0 1-.99-7.88 5.5 5.5 0 0 1 10.86-.82A4.49 4.49 0 0 1 22 10.5a4.5 4.5 0 0 1-4.5 4.5H15z"
      />
      <path
        fill={secondary}
        d="M11 18.59V12a1 1 0 0 1 2 0v6.59l1.3-1.3a1 1 0 0 1 1.4 1.42l-3 3a1 1 0 0 1-1.4 0l-3-3a1 1 0 0 1 1.4-1.42l1.3 1.3z"
      />
    </svg>
  );
}

DownloadIcon.propTypes = {
  primary: PropTypes.string.isRequired,
  secondary: PropTypes.string.isRequired
};

export function ScoreIcon({ primary, secondary }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="icon-chart">
      <path
        fill={primary}
        d="M5 3h14a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5c0-1.1.9-2 2-2zm11 4a1 1 0 0 0-1 1v8a1 1 0 0 0 2 0V8a1 1 0 0 0-1-1z"
      />
      <path
        fill={secondary}
        d="M8 11a1 1 0 0 1 1 1v4a1 1 0 0 1-2 0v-4a1 1 0 0 1 1-1zm4-2a1 1 0 0 1 1 1v6a1 1 0 0 1-2 0v-6a1 1 0 0 1 1-1z"
      />
    </svg>
  );
}

ScoreIcon.propTypes = {
  primary: PropTypes.string.isRequired,
  secondary: PropTypes.string.isRequired
};
