import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { flex } from './utility-styles';

const useStyles = makeStyles((theme) => ({
  frame: {
    ...flex.col('center'),
    margin: theme.spacing(4)
  },
  image: {
    marginBottom: theme.spacing(2),
    height: 160,
    width: 160
  }
}));

function MessageWithImage({ src, alt, children }) {
  const classes = useStyles();

  return (
    <div className={classes.frame}>
      <img className={classes.image} src={src} alt={alt} />
      {children}
    </div>
  );
}
MessageWithImage.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired
};

export default MessageWithImage;
