import React from 'react';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import MessageWithImage from './MessageWithImage';

function NoQuery() {
  return (
    <MessageWithImage src="/gray-logo.svg" alt="No Results">
      <Typography variant="h5" color="textSecondary">
        <Box fontWeight="fontWeightBold">Search for NPM packages</Box>
      </Typography>
      <Typography variant="body1" color="textSecondary">
        (via &nbsp;
        <a href="http://npms.io" target="_blank" rel="noopener noreferrer" className="rb-underline">
          npms.io
        </a>
        &nbsp;API)
      </Typography>
    </MessageWithImage>
  );
}
export default NoQuery;
