import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import CardActions from '@material-ui/core/CardActions';
import Typography from '@material-ui/core/Typography';
import numeral from 'numeral';
import Circle from 'react-circle';

import {DownloadIcon, StarIcon} from '../Icons';
import {colors} from '../../palettes';
import { flex } from '../utility-styles';

const useStyles = makeStyles((theme) => ({
  flexRowSpaceAround: {
    ...flex.row('flex-end', 'space-around'),
    padding: theme.spacing(3),
    borderTop: `1px solid ${colors.tailwind.gray[300]}`,
    color: colors.tailwind.gray[700]
  },
  flexCenterEqual: {
    flex: '1 1 0%',
    justifyContent: 'center'
  },
  inlineFlexCenter: {
    flex: '1 1 0%',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  value: {
    margin: 0,
    lineHeight: 1.5,
    color: colors.tailwind.gray[900],
    fontWeight: 700
  },
  icon: {
    width: '2rem',
    height: '2rem',
    marginRight: theme.spacing(1)
  },
  circle: {
    marginRight: theme.spacing(1)
  },
  label: {
    margin: 0,
    lineHeight: 1.5,
    color: colors.tailwind.gray[700]
  }
}));

const scoreColors = ['#63b3ed', '#fc8181', '#b794f4', '#f687b3', '#7f9cf5', '#4fd1c5', '#68d391', '#f6e05e', '#f6ad55'];

function hashCode(str) {
  let hash = 0;
  let i;
  let chr;
  if (str.length === 0) return hash;
  for (i = 0; i < str.length; i++) {
    chr = str.charCodeAt(i);
    // eslint-disable-next-line no-bitwise
    hash = (hash << 5) - hash + chr;
    // eslint-disable-next-line no-bitwise
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
}


function Statistic({ value, label, children }) {
  const classes = useStyles();
  if (value)
    return (
      <div className={classes.inlineFlexCenter}>
        {children}
        <div>
          <Typography component="p" variant="h6" className={classes.value}>
            {value}
          </Typography>
          <Typography component="p" variant="caption" className={classes.label}>
            {label}
          </Typography>
        </div>
      </div>
    );

  return null;
}

Statistic.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  label: PropTypes.string.isRequired
};

function Statistics({ item, metadata }) {
  const classes = useStyles();
  const gitHubStars = item.gitHubStars ? numeral(item.gitHubStars).format('0a') : '';
  const downloads = numeral(item.downloads).format('0a');
  return (
    <CardActions classes={{ root: classes.flexRowSpaceAround }} disableSpacing>
      <a href={metadata.links.npm} target="_blank" rel="noopener noreferrer" className={classes.flexCenterEqual}>
        <Statistic label="NPM" value={downloads}>
          <div className={classes.icon}>
            <DownloadIcon primary={colors.custom.blueGray[400]} secondary={colors.tailwind.gray[400]} />
          </div>
        </Statistic>
      </a>
      <a href={metadata.links.repository} target="_blank" rel="noopener noreferrer" className={classes.flexCenterEqual}>
        <Statistic label="GitHub" value={gitHubStars}>
          <div className={classes.icon}>
            <StarIcon primary={colors.tailwind.gray[300]} secondary={colors.custom.blueGray[400]} />
          </div>
        </Statistic>
      </a>
      <Statistic label="NPMS" value={Math.round(item.score.final * 100)} className={classes.flexCenterEqual}>
        <div className={classes.circle}>
          <Circle
            size="40"
            lineWidth="30"
            progress={Math.round(item.score.final * 100)}
            progressColor={scoreColors[hashCode(metadata.name) % scoreColors.length]}
            bgColor="#edf2f7"
            percentSpacing={10}
            roundedStroke={false}
            showPercentage={false}
            showPercentageSymbol={false}
          />
        </div>
      </Statistic>
    </CardActions>
  );
}
Statistics.propTypes = {
  item: PropTypes.objectOf(PropTypes.any).isRequired,
  metadata: PropTypes.objectOf(PropTypes.any).isRequired
};


export default Statistics;
