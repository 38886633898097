export const typeColors = ['#465060', '#746c70', '#6b6c6d', '#6a6b6b', '#5e5754', '#5e5d5d'];

export const colors = {
  transparent: 'transparent',

  black: '#000',
  white: '#fff',
  custom: {
    blue2: {
      100: '#DCEFFF',
      200: '#B9DDFF',
      300: '#96C8FF',
      400: '#7CB5FF',
      500: '#5196FF',
      600: '#3B74DB',
      700: '#2855B7',
      800: '#193B93',
      900: '#0F287A',
      transparent: {
        100: 'rgba(81, 150, 255, 0.08)',
        200: 'rgba(81, 150, 255, 0.16)',
        300: 'rgba(81, 150, 255, 0.24)',
        400: 'rgba(81, 150, 255, 0.32)',
        500: 'rgba(81, 150, 255, 0.4)',
        600: 'rgba(81, 150, 255, 0.48)'
      }
    },
    blue3: {
      100: '#DEEBFF',
      200: '#BED6FF',
      300: '#9EBEFF',
      400: '#86ABFF',
      500: '#5E8BFF',
      600: '#446ADB',
      700: '#2F4DB7',
      800: '#1D3493',
      900: '#12237A',
      transparent: {
        100: 'rgba(94, 139, 255, 0.08)',
        200: 'rgba(94, 139, 255, 0.16)',
        300: 'rgba(94, 139, 255, 0.24)',
        400: 'rgba(94, 139, 255, 0.32)',
        500: 'rgba(94, 139, 255, 0.4)',
        600: 'rgba(94, 139, 255, 0.48)'
      }
    },
    blue4: {
      100: '#DEF0FF',
      200: '#BDDFFF',
      300: '#9CCCFF',
      400: '#83BAFF',
      500: '#5B9DFF',
      600: '#4279DB',
      700: '#2D5AB7',
      800: '#1D3E93',
      900: '#112A7A',
      transparent: {
        100: 'rgba(91, 157, 255, 0.08)',
        200: 'rgba(91, 157, 255, 0.16)',
        300: 'rgba(91, 157, 255, 0.24)',
        400: 'rgba(91, 157, 255, 0.32)',
        500: 'rgba(91, 157, 255, 0.4)',
        600: 'rgba(91, 157, 255, 0.48)'
      }
    },
    blue5: {
      100: '#D8F2FD',
      200: '#B1E2FC',
      300: '#89CBF8',
      400: '#6BB4F1',
      500: '#3C92E8',
      600: '#2B71C7',
      700: '#1E54A7',
      800: '#133B86',
      900: '#0B296F',
      transparent: {
        100: 'rgba(60, 146, 232, 0.08)',
        200: 'rgba(60, 146, 232, 0.16)',
        300: 'rgba(60, 146, 232, 0.24)',
        400: 'rgba(60, 146, 232, 0.32)',
        500: 'rgba(60, 146, 232, 0.4)',
        600: 'rgba(60, 146, 232, 0.48)'
      }
    },
    lightBlue: {
      100: '#E2F6FE',
      200: '#C6ECFE',
      300: '#A9DDFD',
      400: '#93CEFB',
      500: '#70B7F9',
      600: '#518FD6',
      700: '#386BB3',
      800: '#234B90',
      900: '#153477',
      transparent: {
        100: 'rgba(112, 183, 249, 0.08)',
        200: 'rgba(112, 183, 249, 0.16)',
        300: 'rgba(112, 183, 249, 0.24)',
        400: 'rgba(112, 183, 249, 0.32)',
        500: 'rgba(112, 183, 249, 0.4)',
        600: 'rgba(112, 183, 249, 0.48)'
      }
    },
    brown: {
      100: '#FBF9F5',
      200: '#F7F2EB',
      300: '#E9E0D8',
      400: '#D3C8C0',
      500: '#B7A9A1',
      600: '#9D8175',
      700: '#835D51',
      800: '#6A3D33',
      900: '#57251E',
      transparent: {
        100: 'rgba(183, 169, 161, 0.08)',
        200: 'rgba(183, 169, 161, 0.16)',
        300: 'rgba(183, 169, 161, 0.24)',
        400: 'rgba(183, 169, 161, 0.32)',
        500: 'rgba(183, 169, 161, 0.4)',
        600: 'rgba(183, 169, 161, 0.48)'
      }
    },
    purpleGray: {
      100: '#F4F3F8',
      200: '#E9E8F2',
      300: '#CCCBD8',
      400: '#A5A4B2',
      500: '#73727F',
      600: '#55536D',
      700: '#3B395B',
      800: '#262449',
      900: '#17153C',
      transparent: {
        100: 'rgba(115, 114, 127, 0.08)',
        200: 'rgba(115, 114, 127, 0.16)',
        300: 'rgba(115, 114, 127, 0.24)',
        400: 'rgba(115, 114, 127, 0.32)',
        500: 'rgba(115, 114, 127, 0.4)',
        600: 'rgba(115, 114, 127, 0.48)'
      }
    },
    pewter: {
      100: '#F9F9F9',
      200: '#F3F3F3',
      300: '#DCDCDC',
      400: '#BABABA',
      500: '#8C8C8C',
      600: '#786667',
      700: '#64464B',
      800: '#512C36',
      900: '#431A28',
      transparent: {
        100: 'rgba(140, 140, 140, 0.08)',
        200: 'rgba(140, 140, 140, 0.16)',
        300: 'rgba(140, 140, 140, 0.24)',
        400: 'rgba(140, 140, 140, 0.32)',
        500: 'rgba(140, 140, 140, 0.4)',
        600: 'rgba(140, 140, 140, 0.48)'
      }
    },
    nickelGray: {
      100: '#F4F7F9',
      200: '#EBEFF3',
      300: '#D1D6DC',
      400: '#AEB3BA',
      500: '#80858C',
      600: '#5D6778',
      700: '#404C64',
      800: '#283451',
      900: '#182343',
      transparent: {
        100: 'rgba(128, 133, 140, 0.08)',
        200: 'rgba(128, 133, 140, 0.16)',
        300: 'rgba(128, 133, 140, 0.24)',
        400: 'rgba(128, 133, 140, 0.32)',
        500: 'rgba(128, 133, 140, 0.4)',
        600: 'rgba(128, 133, 140, 0.48)'
      }
    },
    lighterBlueGray: {
      100: '#E9F5FD',
      200: '#D4E9FC',
      300: '#BCD9F7',
      400: '#A8C8F0',
      500: '#8AB0E6',
      600: '#6488C5',
      700: '#4565A5',
      800: '#2C4585',
      900: '#1A2F6E',
      transparent: {
        100: 'rgba(138, 176, 230, 0.08)',
        200: 'rgba(138, 176, 230, 0.16)',
        300: 'rgba(138, 176, 230, 0.24)',
        400: 'rgba(138, 176, 230, 0.32)',
        500: 'rgba(138, 176, 230, 0.4)',
        600: 'rgba(138, 176, 230, 0.48)'
      }
    },
    blueGray: {
      100: '#EDF2FB',
      200: '#DBE5F7',
      300: '#C1CEE8',
      400: '#A5B3D1',
      500: '#818FB3',
      600: '#5E6D99',
      700: '#414F80',
      800: '#293567',
      900: '#182255',
      transparent: {
        100: 'rgba(129, 143, 179, 0.08)',
        200: 'rgba(129, 143, 179, 0.16)',
        300: 'rgba(129, 143, 179, 0.24)',
        400: 'rgba(129, 143, 179, 0.32)',
        500: 'rgba(129, 143, 179, 0.4)',
        600: 'rgba(129, 143, 179, 0.48)'
      }
    },
    purple: {
      100: '#E8EBFE',
      200: '#D1D7FE',
      300: '#BAC2FD',
      400: '#A8B1FB',
      500: '#8B96F9',
      600: '#656FD6',
      700: '#464EB3',
      800: '#2C3390',
      900: '#1A2077',
      transparent: {
        100: 'rgba(139, 150, 249, 0.08)',
        200: 'rgba(139, 150, 249, 0.16)',
        300: 'rgba(139, 150, 249, 0.24)',
        400: 'rgba(139, 150, 249, 0.32)',
        500: 'rgba(139, 150, 249, 0.4)',
        600: 'rgba(139, 150, 249, 0.48)'
      }
    },
    green: {
      100: '#D3FDD5',
      200: '#A8FCB5',
      300: '#7CF89B',
      400: '#5AF18F',
      500: '#27E87E',
      600: '#1CC77A',
      700: '#13A773',
      800: '#0C8668',
      900: '#076F5F',
      transparent: {
        100: 'rgba(39, 232, 126, 0.08)',
        200: 'rgba(39, 232, 126, 0.16)',
        300: 'rgba(39, 232, 126, 0.24)',
        400: 'rgba(39, 232, 126, 0.32)',
        500: 'rgba(39, 232, 126, 0.4)',
        600: 'rgba(39, 232, 126, 0.48)'
      }
    },
    orange: {
      100: '#FFF4DA',
      200: '#FFE5B6',
      300: '#FFD391',
      400: '#FFC176',
      500: '#FFA449',
      600: '#DB8035',
      700: '#B75F24',
      800: '#934217',
      900: '#7A2E0E',
      transparent: {
        100: 'rgba(255, 164, 73, 0.08)',
        200: 'rgba(255, 164, 73, 0.16)',
        300: 'rgba(255, 164, 73, 0.24)',
        400: 'rgba(255, 164, 73, 0.32)',
        500: 'rgba(255, 164, 73, 0.4)',
        600: 'rgba(255, 164, 73, 0.48)'
      }
    },
    yellow: {
      100: '#FFFBD9',
      200: '#FFF7B3',
      300: '#FFF28D',
      400: '#FFED71',
      500: '#FFE542',
      600: '#DBC130',
      700: '#B79E21',
      800: '#937C15',
      900: '#7A650C',
      transparent: {
        100: 'rgba(255, 229, 66, 0.08)',
        200: 'rgba(255, 229, 66, 0.16)',
        300: 'rgba(255, 229, 66, 0.24)',
        400: 'rgba(255, 229, 66, 0.32)',
        500: 'rgba(255, 229, 66, 0.4)',
        600: 'rgba(255, 229, 66, 0.48)'
      }
    },
    blue: {
      100: '#D6F4FF',
      200: '#ADE5FF',
      300: '#83D2FF',
      400: '#65BFFF',
      500: '#329FFF',
      600: '#247CDB',
      700: '#195CB7',
      800: '#0F4193',
      900: '#092D7A',
      transparent: {
        100: 'rgba(50, 159, 255, 0.08)',
        200: 'rgba(50, 159, 255, 0.16)',
        300: 'rgba(50, 159, 255, 0.24)',
        400: 'rgba(50, 159, 255, 0.32)',
        500: 'rgba(50, 159, 255, 0.4)',
        600: 'rgba(50, 159, 255, 0.48)'
      }
    },
    red: {
      100: '#FFE7D7',
      200: '#FFCAAF',
      300: '#FFA687',
      400: '#FF8469',
      500: '#FF4B38',
      600: '#DB2B28',
      700: '#B71C26',
      800: '#931125',
      900: '#7A0A24',
      transparent: {
        100: 'rgba(255, 75, 56, 0.08)',
        200: 'rgba(255, 75, 56, 0.16)',
        300: 'rgba(255, 75, 56, 0.24)',
        400: 'rgba(255, 75, 56, 0.32)',
        500: 'rgba(255, 75, 56, 0.4)',
        600: 'rgba(255, 75, 56, 0.48)'
      }
    }
  },
  tailwind: {
    gray: {
      100: '#f7fafc',
      200: '#edf2f7',
      300: '#e2e8f0',
      400: '#cbd5e0',
      500: '#a0aec0',
      600: '#718096',
      700: '#4a5568',
      800: '#2d3748',
      900: '#1a202c'
    },
    red: {
      100: '#fff5f5',
      200: '#fed7d7',
      300: '#feb2b2',
      400: '#fc8181',
      500: '#f56565',
      600: '#e53e3e',
      700: '#c53030',
      800: '#9b2c2c',
      900: '#742a2a'
    },
    orange: {
      100: '#fffaf0',
      200: '#feebc8',
      300: '#fbd38d',
      400: '#f6ad55',
      500: '#ed8936',
      600: '#dd6b20',
      700: '#c05621',
      800: '#9c4221',
      900: '#7b341e'
    },
    yellow: {
      100: '#fffff0',
      200: '#fefcbf',
      300: '#faf089',
      400: '#f6e05e',
      500: '#ecc94b',
      600: '#d69e2e',
      700: '#b7791f',
      800: '#975a16',
      900: '#744210'
    },
    green: {
      100: '#f0fff4',
      200: '#c6f6d5',
      300: '#9ae6b4',
      400: '#68d391',
      500: '#48bb78',
      600: '#38a169',
      700: '#2f855a',
      800: '#276749',
      900: '#22543d'
    },
    teal: {
      100: '#e6fffa',
      200: '#b2f5ea',
      300: '#81e6d9',
      400: '#4fd1c5',
      500: '#38b2ac',
      600: '#319795',
      700: '#2c7a7b',
      800: '#285e61',
      900: '#234e52'
    },
    blue: {
      100: '#ebf8ff',
      200: '#bee3f8',
      300: '#90cdf4',
      400: '#63b3ed',
      500: '#4299e1',
      600: '#3182ce',
      700: '#2b6cb0',
      800: '#2c5282',
      900: '#2a4365'
    },
    indigo: {
      100: '#ebf4ff',
      200: '#c3dafe',
      300: '#a3bffa',
      400: '#7f9cf5',
      500: '#667eea',
      600: '#5a67d8',
      700: '#4c51bf',
      800: '#434190',
      900: '#3c366b'
    },
    purple: {
      100: '#faf5ff',
      200: '#e9d8fd',
      300: '#d6bcfa',
      400: '#b794f4',
      500: '#9f7aea',
      600: '#805ad5',
      700: '#6b46c1',
      800: '#553c9a',
      900: '#44337a'
    },
    pink: {
      100: '#fff5f7',
      200: '#fed7e2',
      300: '#fbb6ce',
      400: '#f687b3',
      500: '#ed64a6',
      600: '#d53f8c',
      700: '#b83280',
      800: '#97266d',
      900: '#702459'
    }
  }
};

const palettes = [
  {
    primary: { light: colors.custom.blue[300], main: colors.custom.blue[500], dark: colors.custom.blue[700] },
    success: { light: colors.custom.green[300], main: colors.custom.green[500], dark: colors.custom.green[700] },
    info: { light: colors.tailwind.blue[300], main: colors.tailwind.blue[500], dark: colors.tailwind.blue[700] },
    warning: { light: colors.custom.orange[300], main: colors.custom.orange[500], dark: colors.custom.orange[700] },
    danger: { light: colors.tailwind.red[300], main: colors.tailwind.red[500], dark: colors.tailwind.red[700] },
    secondary: { light: colors.custom.red[300], main: colors.custom.red[500], dark: colors.custom.red[700] }
  },
  {
    primary: { light: colors.custom.blue[300], main: colors.custom.blue[500], dark: colors.custom.blue[700] },
    success: { light: colors.custom.green[300], main: colors.custom.green[500], dark: colors.custom.green[700] },
    info: { light: colors.tailwind.blue[300], main: colors.tailwind.blue[500], dark: colors.tailwind.blue[700] },
    warning: { light: colors.custom.orange[300], main: colors.custom.orange[500], dark: colors.custom.orange[700] },
    danger: { light: colors.tailwind.red[300], main: colors.tailwind.red[500], dark: colors.tailwind.red[700] },
    secondary: { light: colors.custom.pewter[300], main: colors.custom.pewter[500], dark: colors.custom.pewter[700] }
  },
  {
    primary: { light: colors.custom.blue[300], main: colors.custom.blue[500], dark: colors.custom.blue[700] },
    success: { light: colors.custom.green[300], main: colors.custom.green[500], dark: colors.custom.green[700] },
    info: { light: colors.tailwind.blue[300], main: colors.tailwind.blue[500], dark: colors.tailwind.blue[700] },
    warning: { light: colors.custom.orange[300], main: colors.custom.orange[500], dark: colors.custom.orange[700] },
    danger: { light: colors.tailwind.red[300], main: colors.tailwind.red[500], dark: colors.tailwind.red[700] },
    secondary: {
      light: colors.custom.nickelGray[300],
      main: colors.custom.nickelGray[500],
      dark: colors.custom.nickelGray[700]
    }
  },
  {
    primary: { light: colors.tailwind.blue[300], main: colors.tailwind.blue[500], dark: colors.tailwind.blue[700] },
    success: { light: colors.custom.green[300], main: colors.custom.green[500], dark: colors.custom.green[700] },
    info: { light: colors.custom.blue[300], main: colors.custom.blue[500], dark: colors.custom.blue[700] },
    warning: { light: colors.custom.orange[300], main: colors.custom.orange[500], dark: colors.custom.orange[700] },
    danger: { light: colors.tailwind.red[300], main: colors.tailwind.red[500], dark: colors.tailwind.red[700] },
    secondary: { light: colors.custom.red[300], main: colors.custom.red[500], dark: colors.custom.red[700] }
  },
  {
    primary: { light: colors.custom.blue[300], main: colors.custom.blue[500], dark: colors.custom.blue[700] },
    success: { light: colors.tailwind.green[300], main: colors.tailwind.green[500], dark: colors.tailwind.green[700] },
    info: { light: colors.tailwind.blue[300], main: colors.tailwind.blue[500], dark: colors.tailwind.blue[700] },
    warning: {
      light: colors.tailwind.orange[300],
      main: colors.tailwind.orange[500],
      dark: colors.tailwind.orange[700]
    },
    danger: { light: colors.tailwind.red[300], main: colors.tailwind.red[500], dark: colors.tailwind.red[700] },
    secondary: { light: colors.custom.red[300], main: colors.custom.red[500], dark: colors.custom.red[700] }
  },
  {
    primary: { light: '#83D2FF', main: '#329FFF', dark: '#195CB7' },
    success: { light: '#7BF593', main: '#26E070', dark: '#13A167' },
    info: { light: '#7ED5FF', main: '#28A2FF', dark: '#145EB7' },
    warning: { light: '#FFF28D', main: '#FFE542', dark: '#B79E21' },
    danger: { light: '#FEB2A8', main: '#FC6F78', dark: '#B53756' },
    secondary: { light: '#FEB5A6', main: '#fe6b6c', dark: '#B6354D' }
  },
  {
    primary: { light: '#89DBF6', main: '#3BA5E4', dark: '#1D61A4' },
    success: { light: '#7BF593', main: '#26E070', dark: '#13A167' },
    info: { light: '#83D2FF', main: '#329FFF', dark: '#195CB7' },
    warning: { light: '#FFDA96', main: '#FFB351', dark: '#B76C28' },
    danger: { light: '#FFA988', main: '#FF513A', dark: '#B71D23' },
    secondary: { light: '#FEA5A0', main: '#fe6278', dark: '#B6315D' }
  },
  {
    primary: { light: '#99D6FB', main: '#55a7f2', dark: '#2A61AE' },
    success: { light: '#7BF593', main: '#26E070', dark: '#13A167' },
    info: { light: '#83D2FF', main: '#329FFF', dark: '#195CB7' },
    warning: { light: '#FFDA96', main: '#FFB351', dark: '#B76C28' },
    danger: { light: '#FFA988', main: '#FF513A', dark: '#B71D23' },
    secondary: { light: '#FEA5A0', main: '#fe6278', dark: '#B6315D' }
  },
  {
    primary: { light: '#96D2F6', main: '#4F9CE3', dark: '#275BA3' },
    success: { light: '#7BF593', main: '#26E070', dark: '#13A167' },
    info: { light: '#83D2FF', main: '#329FFF', dark: '#195CB7' },
    warning: { light: '#FFDA96', main: '#FFB351', dark: '#B76C28' },
    danger: { light: '#FFA988', main: '#FF513A', dark: '#B71D23' },
    secondary: { light: '#FEA5A0', main: '#fe6278', dark: '#B6315D' }
  },
  {
    primary: { light: '#96D2F6', main: '#4f9ce3', dark: '#14619B' },
    success: { light: '#7BF593', main: '#26E070', dark: '#13A167' },
    info: { light: '#83D2FF', main: '#329FFF', dark: '#195CB7' },
    warning: { light: '#FFDA96', main: '#FFB351', dark: '#B76C28' },
    danger: { light: '#FFA988', main: '#FF513A', dark: '#B71D23' },
    secondary: { light: '#FEA5A0', main: '#fe6278', dark: '#B6315D' }
  },
  {
    primary: { light: '#81CEFD', main: '#2F98FA', dark: '#1758B3' },
    success: { light: '#B5EA7F', main: '#64BC2D', dark: '#348716' },
    info: { light: '#A9CEFD', main: '#70A2F9', dark: '#385CB3' },
    warning: { light: '#FFD978', main: '#FFB01E', dark: '#B7700F' },
    danger: { light: '#FFA785', main: '#FF4D35', dark: '#B71A21' },
    secondary: { light: '#FEA5A0', main: '#fe6278', dark: '#B6315D' }
  },
  {
    primary: { light: '#9BE9FD', main: '#59C4F9', dark: '#2266A4' },
    success: { light: '#7BF593', main: '#26E070', dark: '#13A167' },
    info: { light: '#83D2FF', main: '#329FFF', dark: '#195CB7' },
    warning: { light: '#FFDA96', main: '#FFB351', dark: '#B76C28' },
    danger: { light: '#FFA988', main: '#FF513A', dark: '#B71D23' },
    secondary: { light: '#FEA5A0', main: '#fe6278', dark: '#B6315D' }
  },
  {
    primary: { light: '#8EDFF6', main: '#44ace4', dark: '#2C75B3' },
    success: { light: '#7BF593', main: '#26E070', dark: '#13A167' },
    info: { light: '#83D2FF', main: '#329FFF', dark: '#195CB7' },
    warning: { light: '#FFDA96', main: '#FFB351', dark: '#B76C28' },
    danger: { light: '#FFA988', main: '#FF513A', dark: '#B71D23' },
    secondary: { light: '#FEA5A0', main: '#fe6278', dark: '#B6315D' }
  },

  {
    primary: { light: '#8FE4FC', main: '#46baf7', dark: '#236EB1' },
    success: { light: '#7BF593', main: '#26E070', dark: '#13A167' },
    info: { light: '#83D2FF', main: '#329FFF', dark: '#195CB7' },
    warning: { light: '#FFDA96', main: '#FFB351', dark: '#B76C28' },
    danger: { light: '#FFA988', main: '#FF513A', dark: '#B71D23' },
    secondary: { light: '#FEA5A0', main: '#fe6278', dark: '#B6315D' }
  },
  {
    primary: { light: '#E9E0D8', main: '#B7A9A1', dark: '#835D51' },
    success: { light: '#6EF49D', main: '#13DB81', dark: '#099D78' },
    info: { light: '#8AE4FF', main: '#3DBBFF', dark: '#1E6EB7' },
    warning: { light: '#FFF488', main: '#FFE83A', dark: '#B7A11D' },
    danger: { light: '#FFAC99', main: '#FF5956', dark: '#B72B40' },
    secondary: { light: '#CCCBD8', main: '#73727F', dark: '#3B395B' }
  },
  {
    primary: { light: '#82D2F5', main: '#3197E0', dark: '#1858A1' },
    success: { light: '#7BF593', main: '#26E070', dark: '#13A167' },
    info: { light: '#83D2FF', main: '#329FFF', dark: '#195CB7' },
    warning: { light: '#FFDA96', main: '#FFB351', dark: '#B76C28' },
    danger: { light: '#FFA988', main: '#FF513A', dark: '#B71D23' },
    secondary: { light: '#FEA5A0', main: '#fe6278', dark: '#B6315D' }
  },
  {
    primary: { light: '#90CAF1', main: '#458BD1', dark: '#225196' },
    success: { light: '#74F193', main: '#1DD16E', dark: '#0E9666' },
    info: { light: '#9BDCFF', main: '#59B4FF', dark: '#2C69B7' },
    warning: { light: '#FFE08D', main: '#FFC042', dark: '#B77B21' },
    danger: { light: '#FFA78E', main: '#FF4E44', dark: '#B72233' },
    secondary: { light: '#FDADA2', main: '#fa646c', dark: '#B33250' }
  },
  {
    primary: { light: '#90CAF1', main: '#458BD1', dark: '#225196' },
    success: { light: '#74F193', main: '#1DD16E', dark: '#0E9666' },
    info: { light: '#9BDCFF', main: '#59B4FF', dark: '#2C69B7' },
    warning: { light: '#FFE36C', main: '#FFC50A', dark: '#B78405' },
    danger: { light: '#FF988B', main: '#FF3F4C', dark: '#B71F45' },
    secondary: { light: '#FB8E94', main: '#F34370', dark: '#AE2161' }
  },
  {
    primary: { light: '#90CAF1', main: '#458BD1', dark: '#225196' },
    success: { light: '#74F193', main: '#1DD16E', dark: '#0E9666' },
    info: { light: '#9BDCFF', main: '#59B4FF', dark: '#2C69B7' },
    warning: { light: '#FFE36C', main: '#FFC50A', dark: '#B78405' },
    danger: { light: '#FFA58A', main: '#FF4A3D', dark: '#B71E2D' },
    secondary: { light: '#FB8E94', main: '#F34370', dark: '#AE2161' }
  },
  {
    primary: { light: '#89DBF6', main: '#3BA5E4', dark: '#1D61A4' },
    success: { light: '#79EE8E', main: '#24C963', dark: '#12905B' },
    info: { light: '#A0D8FD', main: '#61ADF9', dark: '#3065B3' },
    warning: { light: '#FFF576', main: '#FFEC1C', dark: '#B7A60E' },
    danger: { light: '#FF9C8E', main: '#FF444E', dark: '#B72244' },
    secondary: { light: '#FC8597', main: '#F63579', dark: '#B11A6D' }
  },
  {
    primary: { light: '#89DBF6', main: '#3BA5E4', dark: '#1D61A4' },
    success: { light: '#C9EA69', main: '#84BA10', dark: '#558508' },
    info: { light: '#A3E1FC', main: '#65BAF7', dark: '#326EB1' },
    warning: { light: '#FFDD81', main: '#FFB92D', dark: '#B77616' },
    danger: { light: '#FF9F87', main: '#FF3E38', dark: '#B71C31' },
    secondary: { light: '#FC8597', main: '#F63579', dark: '#B11A6D' }
  },
  {
    primary: { light: '#89DBF6', main: '#3BA5E4', dark: '#1D61A4' },
    success: { light: '#C6EE81', main: '#82C930', dark: '#4D9018' },
    info: { light: '#B0D2FE', main: '#7BAAFC', dark: '#3D60B5' },
    warning: { light: '#FDF070', main: '#F9DF13', dark: '#B39C09' },
    danger: { light: '#FFAC7C', main: '#FF5526', dark: '#B71E13' },
    secondary: { light: '#FC8597', main: '#F63579', dark: '#B11A6D' }
  },
  {
    primary: { light: '#E9E0D8', main: '#B7A9A1', dark: '#835D51' },
    success: { light: '#88EE87', main: '#38C952', dark: '#1C9047' },
    info: { light: '#89D8FE', main: '#3CA9FC', dark: '#1E63B5' },
    warning: { light: '#FEF171', main: '#FCE114', dark: '#B59D0A' },
    danger: { light: '#FFB09E', main: '#FF615E', dark: '#B72F44' },
    secondary: { light: '#E9E0D8', main: '#B7A9A1', dark: '#835D51' }
  },
  {
    primary: { light: '#E9E0D8', main: '#B7A9A1', dark: '#835D51' },
    success: { light: '#70F396', main: '#17D875', dark: '#0B9B6E' },
    info: { light: '#8BDEFF', main: '#3FB2FF', dark: '#1F68B7' },
    warning: { light: '#FFE088', main: '#FFC03A', dark: '#B77C1D' },
    danger: { light: '#FF9B9C', main: '#FF597A', dark: '#B72C62' },
    secondary: { light: '#E9E0D8', main: '#B7A9A1', dark: '#835D51' }
  },
  {
    primary: { light: '#DCDCDC', main: '#8C8C8C', dark: '#64464B' },
    success: { light: '#CEEA78', main: '#8CBC23', dark: '#5A8711' },
    info: { light: '#9CD9FF', main: '#5BB0FF', dark: '#2D67B7' },
    warning: { light: '#FFE867', main: '#FFD002', dark: '#B78E01' },
    danger: { light: '#FFA19C', main: '#FF5B71', dark: '#B72D59' },
    secondary: { light: '#DCDCDC', main: '#8C8C8C', dark: '#64464B' }
  },
  {
    primary: { light: '#DCDCDC', main: '#8C8C8C', dark: '#64464B' },
    success: { light: '#B2E993', main: '#5FB747', dark: '#2A8323' },
    info: { light: '#63E6F4', main: '#02A8DB', dark: '#01629D' },
    warning: { light: '#FDE98C', main: '#F9D140', dark: '#B38C20' },
    danger: { light: '#FFB49F', main: '#FF6860', dark: '#B73040' },
    secondary: { light: '#DCDCDC', main: '#8C8C8C', dark: '#64464B' }
  },
  {
    primary: { light: '#DCD6D4', main: '#8C8484', dark: '#644248' },
    success: { light: '#7CF899', main: '#27EA7C', dark: '#13A872' },
    info: { light: '#79EBFF', main: '#21C0FF', dark: '#1071B7' },
    warning: { light: '#FFEE75', main: '#FFDC19', dark: '#B7980C' },
    danger: { light: '#FF9E90', main: '#FF4750', dark: '#B72345' },
    secondary: { light: '#DCD6D4', main: '#8C8484', dark: '#644248' }
  },
  {
    primary: { light: '#C1CEE8', main: '#818FB3', dark: '#414F80' },
    success: { light: '#6DF89B', main: '#10E883', dark: '#08A77C' },
    info: { light: '#98D3FF', main: '#54A6FF', dark: '#2A60B7' },
    warning: { light: '#FFEE8D', main: '#FFDC42', dark: '#B79521' },
    danger: { light: '#FFA090', main: '#FF474D', dark: '#B72342' },
    secondary: { light: '#C1CEE8', main: '#818FB3', dark: '#414F80' }
  },
  {
    primary: { light: '#A3C0F4', main: '#6386DB', dark: '#314A9D' },
    success: { light: '#83EA84', main: '#32BA4D', dark: '#198543' },
    info: { light: '#9EE2FC', main: '#5DBAF7', dark: '#2E6EB1' },
    warning: { light: '#FFD49B', main: '#FFA659', dark: '#B75E2C' },
    danger: { light: '#FF9A8B', main: '#FF3F49', dark: '#B71F42' },
    secondary: { light: '#A3C0F4', main: '#6386DB', dark: '#314A9D' }
  },
  {
    primary: { light: '#D1D6DC', main: '#80858C', dark: '#404C64' },
    success: { light: '#C9EC76', main: '#86C120', dark: '#548A10' },
    info: { light: '#9FE5FE', main: '#5FC0FC', dark: '#2F72B5' },
    warning: { light: '#FFE666', main: '#FFCC00', dark: '#B78B00' },
    danger: { light: '#FFAAA9', main: '#FF7088', dark: '#B73865' },
    secondary: { light: '#D1D6DC', main: '#80858C', dark: '#404C64' }
  },
  {
    primary: { light: '#D1D6DC', main: '#80858C', dark: '#404C64' },
    success: { light: '#C9EB7A', main: '#84BF26', dark: '#528913' },
    info: { light: '#97E8FD', main: '#52C2F9', dark: '#2974B3' },
    warning: { light: '#FFDE66', main: '#FFBB00', dark: '#B77C00' },
    danger: { light: '#FFACA9', main: '#FF7085', dark: '#B73862' },
    secondary: { light: '#D1D6DC', main: '#80858C', dark: '#404C64' }
  },
  {
    primary: { light: '#D3D7DB', main: '#81858A', dark: '#414D63' },
    success: { light: '#BCEF77', main: '#71CC22', dark: '#3F9211' },
    info: { light: '#AADBFC', main: '#71B4F7', dark: '#3869B1' },
    warning: { light: '#FFD866', main: '#FFAE00', dark: '#B77100' },
    danger: { light: '#FFAFB5', main: '#FF7A9F', dark: '#B73D77' },
    secondary: { light: '#D3D7DB', main: '#81858A', dark: '#414D63' }
  },
  {
    primary: { light: '#D5D8DB', main: '#83868A', dark: '#424D63' },
    success: { light: '#CCEF83', main: '#8ECC33', dark: '#579219' },
    info: { light: '#7FBDFF', main: '#2B80FF', dark: '#1549B7' },
    warning: { light: '#FFED8B', main: '#FFDB3F', dark: '#B7951F' },
    danger: { light: '#FFB28A', main: '#FF633D', dark: '#B7251E' },
    secondary: { light: '#D5D8DB', main: '#83868A', dark: '#424D63' }
  },
  {
    primary: { light: '#C7CFD5', main: '#686E75', dark: '#344054' },
    success: { light: '#70F19B', main: '#17D37B', dark: '#0B9772' },
    info: { light: '#9FE2FF', main: '#60BDFF', dark: '#306FB7' },
    warning: { light: '#FDEC72', main: '#F9D716', dark: '#B3940B' },
    danger: { light: '#FF9E8A', main: '#FF3D3D', dark: '#B71E39' },
    secondary: { light: '#C7CFD5', main: '#686E75', dark: '#344054' }
  },
  {
    primary: { light: '#D5D8DB', main: '#83868A', dark: '#424D63' },
    success: { light: '#9CF194', main: '#4BD158', dark: '#259645' },
    info: { light: '#74C4FE', main: '#1987FC', dark: '#0C4EB5' },
    warning: { light: '#FFE266', main: '#FFC300', dark: '#B78300' },
    danger: { light: '#FFBEB0', main: '#FF7C7C', dark: '#B73E53' },
    secondary: { light: '#D5D8DB', main: '#83868A', dark: '#424D63' }
  },
  {
    primary: { light: '#C1CEE8', main: '#818FB3', dark: '#414F80' },
    success: { light: '#6DF89B', main: '#10E883', dark: '#08A77C' },
    info: { light: '#98D3FF', main: '#54A6FF', dark: '#2A60B7' },
    warning: { light: '#FFEE8D', main: '#FFDC42', dark: '#B79521' },
    danger: { light: '#FFA090', main: '#FF474D', dark: '#B72342' },
    secondary: { light: '#C1CEE8', main: '#818FB3', dark: '#414F80' }
  },
  {
    primary: { light: '#C1CEE8', main: '#818FB3', dark: '#414F80' },
    success: { light: '#87F186', main: '#37D353', dark: '#1B9749' },
    info: { light: '#87C2FF', main: '#388AFF', dark: '#1C4FB7' },
    warning: { light: '#FFD5A6', main: '#FFA86B', dark: '#B75C35' },
    danger: { light: '#FF8F8B', main: '#FF3F5C', dark: '#B71F53' },
    secondary: { light: '#C1CEE8', main: '#818FB3', dark: '#414F80' }
  },
  {
    primary: { light: '#C1CEE8', main: '#818FB3', dark: '#414F80' },
    success: { light: '#BAEC7E', main: '#6DC12C', dark: '#3C8A16' },
    info: { light: '#93E2FC', main: '#4CB8F7', dark: '#266DB1' },
    warning: { light: '#FBD786', main: '#F2AA37', dark: '#AE6A1B' },
    danger: { light: '#FFC0AD', main: '#FF8077', dark: '#B73B48' },
    secondary: { light: '#C1CEE8', main: '#818FB3', dark: '#414F80' }
  },
  {
    primary: { light: '#A7C3FB', main: '#6B91F2', dark: '#3550AE' },
    success: { light: '#7DF494', main: '#2ADD6F', dark: '#159F65' },
    info: { light: '#87D3FF', main: '#38A2FF', dark: '#1C5EB7' },
    warning: { light: '#FFF28D', main: '#FFE542', dark: '#B79E21' },
    danger: { light: '#FF998A', main: '#FF3D46', dark: '#B71E41' },
    secondary: { light: '#A7C3FB', main: '#6B91F2', dark: '#3550AE' }
  },
  {
    primary: { light: '#A7C3FB', main: '#6B91F2', dark: '#3550AE' },
    success: { light: '#C5EA74', main: '#7DBC1E', dark: '#4D870F' },
    info: { light: '#66CDFF', main: '#0090FF', dark: '#0053B7' },
    warning: { light: '#FFEC66', main: '#FFD800', dark: '#B79500' },
    danger: { light: '#FF9E8A', main: '#FF3D3D', dark: '#B71E39' },
    secondary: { light: '#A7C3FB', main: '#6B91F2', dark: '#3550AE' }
  },
  {
    primary: { light: '#A7C3FB', main: '#6B91F2', dark: '#3550AE' },
    success: { light: '#A3EB7F', main: '#48BF2D', dark: '#1A8916' },
    info: { light: '#A3E4FE', main: '#67C0FC', dark: '#3372B5' },
    warning: { light: '#FFD29B', main: '#FFA359', dark: '#B75B2C' },
    danger: { light: '#FF9C8E', main: '#FF444E', dark: '#B72244' },
    secondary: { light: '#A7C3FB', main: '#6B91F2', dark: '#3550AE' }
  },
  {
    primary: { light: '#A7C3FB', main: '#6B91F2', dark: '#3550AE' },
    success: { light: '#A6F387', main: '#4DD838', dark: '#1C9B1F' },
    info: { light: '#B8CCFD', main: '#89A3F9', dark: '#4559B3' },
    warning: { light: '#FBEA6C', main: '#F4D10C', dark: '#AF9006' },
    danger: { light: '#FFC28A', main: '#FF813D', dark: '#B7411E' },
    secondary: { light: '#A7C3FB', main: '#6B91F2', dark: '#3550AE' }
  },
  {
    primary: { light: '#A3C0F4', main: '#6386DB', dark: '#314A9D' },
    success: { light: '#C7ED83', main: '#82C433', dark: '#4D8D19' },
    info: { light: '#ACD6FF', main: '#75B0FF', dark: '#3A65B7' },
    warning: { light: '#FDE680', main: '#F9CA2C', dark: '#B38716' },
    danger: { light: '#FFAD7E', main: '#FF5728', dark: '#B72014' },
    secondary: { light: '#A3C0F4', main: '#6386DB', dark: '#314A9D' }
  },
  {
    primary: { light: '#BFD3F6', main: '#8FA8E4', dark: '#485DA4' },
    success: { light: '#D0ED72', main: '#93C61B', dark: '#608E0D' },
    info: { light: '#9BCBFF', main: '#599BFF', dark: '#2C58B7' },
    warning: { light: '#FFD36F', main: '#FFA30F', dark: '#B76607' },
    danger: { light: '#FF9F98', main: '#FF5468', dark: '#B72A54' },
    secondary: { light: '#BFD3F6', main: '#8FA8E4', dark: '#485DA4' }
  },
  {
    primary: { light: '#BFD3F6', main: '#8FA8E4', dark: '#485DA4' },
    success: { light: '#C3EB77', main: '#7BBF22', dark: '#4A8911' },
    info: { light: '#8AB6FC', main: '#3D78F7', dark: '#1E43B1' },
    warning: { light: '#FFDA66', main: '#FFB200', dark: '#B77400' },
    danger: { light: '#FFC1B0', main: '#FF837C', dark: '#B73E4C' },
    secondary: { light: '#BFD3F6', main: '#8FA8E4', dark: '#485DA4' }
  },
  {
    primary: { light: '#81CEFD', main: '#2F98FA', dark: '#1758B3' },
    success: { light: '#B5EA7F', main: '#64BC2D', dark: '#348716' },
    info: { light: '#A9CEFD', main: '#70A2F9', dark: '#385CB3' },
    warning: { light: '#FFD978', main: '#FFB01E', dark: '#B7700F' },
    danger: { light: '#FFA785', main: '#FF4D35', dark: '#B71A21' },
    secondary: { light: '#81CEFD', main: '#2F98FA', dark: '#1758B3' }
  },
  {
    primary: { light: '#7CD0F8', main: '#2896EA', dark: '#1457A8' },
    success: { light: '#B2E977', main: '#5EB722', dark: '#318311' },
    info: { light: '#B3CFFD', main: '#81A7F9', dark: '#415DB3' },
    warning: { light: '#FFDC70', main: '#FFB711', dark: '#B77708' },
    danger: { light: '#FFAE8D', main: '#FF5B42', dark: '#B72125' },
    secondary: { light: '#7CD0F8', main: '#2896EA', dark: '#1457A8' }
  }
];
export default palettes;
