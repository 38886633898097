import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import CardHeader from '@material-ui/core/CardHeader';
import Avatar from '@material-ui/core/Avatar';
import md5 from 'md5';
import get from 'lodash.get';
import { colors } from '../../palettes';

const useStyles = makeStyles(() => ({
  frame: {
    padding: '1.5rem',
    backgroundColor: colors.tailwind.gray[300]
  },
  noMargin: {
    margin: 0
  },
  avatar: {
    width: '4rem',
    height: '4rem'
  },
  owner: {
    margin: 0,
    lineHeight: 1.5,
    fontWeight: 400,
    color: colors.tailwind.gray[700]
  },
  name: {
    margin: 0,
    lineHeight: 1.5,
    fontWeight: 700,
    color: colors.tailwind.gray[900]
  }
}));

function determineOwner(metadata) {
  const author = {};
  author.name = get(metadata, 'author.name') || get(metadata, 'publisher.username');
  author.email = get(metadata, 'author.email') || get(metadata, 'publisher.email');
  return author;
}

function PackageHeader({ metadata }) {
  const classes = useStyles();
  const owner = determineOwner(metadata);

  const ownerAvatar = (
    <Avatar
      className={classes.avatar}
      alt={owner.name}
      src={`https://www.gravatar.com/avatar/${md5(owner.email)}?size=64`}
    />
  );

  return (
    <a href={metadata.links.homepage} target="_blank" rel="noopener noreferrer">
      <CardHeader
        classes={{ root: classes.frame, action: classes.noMargin, title: classes.name, subheader: classes.owner }}
        avatar={ownerAvatar}
        title={metadata.name}
        titleTypographyProps={{ variant: 'subtitle1' }}
        subheader={owner.name}
        subheaderTypographyProps={{ variant: 'subtitle2' }}
      />
    </a>
  );
}
PackageHeader.propTypes = {
  metadata: PropTypes.objectOf(PropTypes.any).isRequired
};

export default PackageHeader;
