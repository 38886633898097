import React from 'react';
import numeral from 'numeral';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { colors } from '../palettes';
import SortOptions from './SortOptions';
import { flex } from './utility-styles';
import { useDomainModel } from '../model/ModelContext';

const useStyles = makeStyles((theme) => ({
  results: {
    lineHeight: 1,
    fontSize: '0.875rem',
    color: colors.tailwind.gray[600]
  },
  sortFrame: {
    ...flex.row('center', 'flex-start', 'wrap'),
    margin: theme.spacing(0, 0, 0.5, 12),
    [theme.breakpoints.down('xs')]: {
      marginLeft: theme.spacing(2)
    },
    '& > *': {
      marginRight: theme.spacing(2),
      marginBottom: theme.spacing(1)
    }
  }
}));

function SortOptionsAndResultsStatus() {
  const classes = useStyles();
  const { state } = useDomainModel();
  return (
    <div className={classes.sortFrame}>
      <SortOptions />
      <Typography variant="body1" className={classes.results}>
        {numeral(state.packages.total).format('0a')}
        &nbsp; results
      </Typography>
    </div>
  );
}

export default SortOptionsAndResultsStatus;
