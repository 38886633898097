function createDefaultPackages() {
  return { total: -1, items: [] };
}

function createDefaultState() {
  return {
    packages: createDefaultPackages(),
    query: '',
    selected: [],
    sort: 'Relevance',
    loading: false,
    errors: []
  };
}

function sortPackages(packages, sort) {
  const { items, total } = packages;
  const sorters = {
    Relevance: (a, b) => b.searchScore - a.searchScore,
    Stars: (a, b) => b.gitHubStars - a.gitHubStars,
    Downloads: (a, b) => b.downloads - a.downloads,
    Score: (a, b) => b.score.final - a.score.final
  };

  const sorter = sorters[sort];
  if (sorter) items.sort(sorter);

  return { total, items };
}

const changeQuery = (q, state) => {
  if (q) return { ...state, query: q };
  return { ...state, packages: createDefaultPackages(), query: '' };
};

const sort = (key, state) => ({ ...state, sort: key, packages: sortPackages(state.packages, key) });
const searchStart = (query, state) => {

  if (query) return { ...state, loading: true, query };
  return { ...state, loading: true };
};
const searchDone = (packages, state) => ({
  ...state,
  loading: false,
  errors: [],
  packages: sortPackages(packages, state.sort)
});
const searchFailed = (errors, state) => ({
  ...state,
  errors,
  packages: createDefaultPackages()
});

const resetPackages = (state) => ({
  ...state,
  packages: createDefaultPackages()
});

export {
  changeQuery,
  sort,
  searchDone,
  searchFailed,
  searchStart,
  resetPackages,
  createDefaultState
};
