import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import {colors} from '../../palettes';

const useStyles = makeStyles((theme) => ({
  content: {
    margin: theme.spacing(2, 3, 2, 3),
    padding: 0
  },
  truncate3Lines: {
    margin: 0,
    lineHeight: 1.5,
    height: '4rem',
    color: colors.tailwind.gray[700],
    overflow: 'hidden',
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': 3
  }
}));

function Description({ metadata }) {
  const classes = useStyles();
  return (
    <CardContent classes={{ root: classes.content }}>
      <Typography variant="body2" className={classes.truncate3Lines}>
        {metadata.description}
      </Typography>
    </CardContent>
  );
}

Description.propTypes = {
  metadata: PropTypes.objectOf(PropTypes.any).isRequired
};

export default Description;
