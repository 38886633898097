import React from 'react';
import PropTypes from 'prop-types';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { colors } from '../palettes';
import { border, borderRadius } from './utility-styles';
import { useDomainModel } from '../model/ModelContext';

const commonStyles = (theme) => ({
  boxShadow: 'none',
  fontWeight: 700,
  fontSize: '0.75rem',
  borderRadius: 0,
  borderRight: border('tailwind.gray', 400),
  padding: theme.spacing(0.5, 1.5, 0.5, 1.5),
  '&:hover ': {
    color: colors.tailwind.gray[700],
    backgroundColor: colors.custom.blue5[300],
    boxShadow: 'none'
  }
});

const useStyles = makeStyles((theme) => ({
  selected: {
    ...commonStyles(theme),
    color: colors.white,
    backgroundColor: colors.custom.blue5[500]
  },
  unselected: {
    ...commonStyles(theme),
    color: colors.tailwind.gray[600],
    backgroundColor: colors.tailwind.gray[300]
  }
}));

function SortButton({ selected, style, name, onSelectedChange }) {
  const classes = useStyles();
  function onClick() {
    onSelectedChange(name);
  }
  return (
    <Button
      variant="contained"
      onClick={onClick}
      style={style}
      classes={{
        root: selected === name ? classes.selected : classes.unselected
      }}
    >
      {name}
    </Button>
  );
}

SortButton.propTypes = {
  selected: PropTypes.string.isRequired,
  style: PropTypes.objectOf(PropTypes.any),
  name: PropTypes.string.isRequired,
  onSelectedChange: PropTypes.func.isRequired
};

function SortOptions() {
  const { state, services } = useDomainModel();
  return (
    <ButtonGroup size="small">
      <SortButton
        style={{ ...borderRadius(8, 'none') }}
        name="Relevance"
        selected={state.sort}
        onSelectedChange={services.actions.sort}
      />
      <SortButton
        selected={state.sort}
        name="Downloads"
        onSelectedChange={services.actions.sort}
      />
      <SortButton
        selected={state.sort}
        name="Stars"
        onSelectedChange={services.actions.sort}
      />
      <SortButton
        style={{ ...borderRadius('none', 8), borderRight: 'none' }}
        selected={state.sort}
        onSelectedChange={services.actions.sort}
        name="Score"
      />
    </ButtonGroup>
  );
}

export default SortOptions;
