import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MuiAppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import { GoMarkGithub } from 'react-icons/go';
import QueryInput from './QueryInput';
import { FillSpace } from './Layouts';
import { border } from './utility-styles';

import Logo from './Logo';
import {useDomainModel} from "../model/ModelContext";

const useStyles = makeStyles((theme) => ({
  noShadowTopBorder: {
    boxShadow: 'none',
    backgroundColor: '#ffffff',
    borderTop: border('tailwind.blue', 500, 2),
    padding: theme.spacing(0.5, 0)
  },
  noLeftMarginPadding: {
    marginLeft: 0,
    paddingLeft: 0
  },
  icon: {
    height: 24,
    width: 24
  }
}));
function Loading() {
  const { state } = useDomainModel();
  return state.loading ? <div className="throbber-loader">...</div> : null;
}

function AppBar() {
  const classes = useStyles();

  return (
    <MuiAppBar position="relative" classes={{ root: classes.noShadowTopBorder }}>
      <Toolbar classes={{ root: classes.noLeftMarginPadding }}>
        <Logo />
        <QueryInput />
        <Loading />
        <FillSpace />
        <IconButton edge="end" aria-label="GitHub Repo" classes={{ label: classes.icon }}>
          <a href="https://github.com/csepulv" target="_blank" rel="noopener noreferrer">
            <GoMarkGithub color="#818FB3" />
          </a>
        </IconButton>
      </Toolbar>
    </MuiAppBar>
  );
}

export default AppBar;
