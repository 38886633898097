import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import CardActions from '@material-ui/core/CardActions';
import { border, flex } from '../utility-styles';
import { colors } from '../../palettes';

const useStyles = makeStyles((theme) => ({
  license: {
    margin: 0,
    lineHeight: '21px',
    color: colors.tailwind.gray[600]
  },
  updated: {
    margin: 0,
    lineHeight: 1.5,
    fontSize: '0.75rem',
    color: colors.tailwind.gray[600]
  },
  content: {
    ...flex.row('flex-end', 'space-between'),
    borderTop: border(),
    margin: 'auto 0 0 0',
    padding: theme.spacing(1, 3, 1, 3),
    backgroundColor: colors.tailwind.gray[300]
  }
}));

function LicenseAndUpdated({ metadata }) {
  const classes = useStyles();
  return (
    <CardActions classes={{ root: classes.content }}>
      <Typography component="p" variant="caption" className={classes.updated}>
        {moment(metadata.date).fromNow()}
      </Typography>
      <Typography variant="caption" className={classes.license}>
        {metadata.license}
      </Typography>
    </CardActions>
  );
}

LicenseAndUpdated.propTypes = {
  metadata: PropTypes.objectOf(PropTypes.any).isRequired
};

export default LicenseAndUpdated;
