import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import FlipMove from 'react-flip-move';
import Package from './package/Package';
import { flex } from './utility-styles';
import { useDomainModel } from '../model/ModelContext';

const useStyles = makeStyles(() => ({
  frame: {
    ...flex.row(null, 'center', 'wrap')
  }
}));

function SearchItems() {
  const classes = useStyles();
  const { state, services } = useDomainModel();
  if (!services.conditions.hasSearchResults()) return null;
  const { items } = state.packages;

  return (
    <FlipMove className={classes.frame}>
      {items.map((item) => (
        <Package key={item.collected.metadata.name} item={item} />
      ))}
    </FlipMove>
  );
}

export default SearchItems;
