import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import PackageHeader from './PackageHeader';
import Statistics from './Statistics';
import Description from './Description';
import LicenseAndUpdated from './LicenseAndUpdated';
import { colors } from '../../palettes';
import { flex } from '../utility-styles';

const useStyles = makeStyles((theme) => ({
  frame: {
    ...flex.col(),
    '& a': {
      textDecoration: 'none'
    },
    maxWidth: '24rem',
    width: '80%',
    margin: '1.5rem',
    backgroundColor: colors.white,
    boxShadow:
      '0px 6px 10px -3px rgba(0,0,0,0.15), 0px 10px 14px 1px rgba(0,0,0,0.10), 0px 4px 18px 3px rgba(0,0,0,0.06)',
    borderRadius: theme.shape.borderRadius * 2,
    overflow: 'hidden'
  }
}));

const Package = forwardRef((props, ref) => {
  const { item } = props;
  const classes = useStyles();
  const { metadata } = item.collected;

  return (
    <Card className={classes.frame} ref={ref}>
      <PackageHeader metadata={metadata} />
      <Description metadata={metadata} />
      <Statistics metadata={metadata} item={item} />
      <LicenseAndUpdated metadata={metadata} />
    </Card>
  );
});

Package.propTypes = {
  item: PropTypes.objectOf(PropTypes.any).isRequired
};

export default Package;
