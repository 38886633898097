import SearchService from '../services/search-service';
import {
  changeQuery,
  sort,
  searchDone,
  searchFailed,
  searchStart,
  resetPackages,
  createDefaultState
} from './mutators';

function reducer(state, action) {
  return action.handle(state);
}

function actionFor(handler, arg) {
  if (arg !== undefined) return { handle: handler.bind(null, arg) };
  return { handle: handler };
}

async function search(query = null, getCurrentState, conditions, dispatch) {
  try {
    if (query || conditions.hasQuery()) {
      const queryFoSearch = query || getCurrentState().query;

      dispatch(actionFor(searchStart, query));
      const packages = await SearchService.search(queryFoSearch);

      dispatch(actionFor(searchDone, packages));
    } else {
      dispatch(actionFor(resetPackages));
    }
  } catch (ex) {
    dispatch(actionFor(searchFailed, [ex]));
  }
}

function createServicesForContext(getCurrentState, dispatch) {
  const conditions = {
    hasSearchResults: () => getCurrentState().packages.total >= 0,
    hasQuery: () => getCurrentState().query && getCurrentState().query.length > 0,
    hasEmptySearchResults: () => getCurrentState().packages.total === 0
  };

  const effects = {
    search: async (query) => search(query, getCurrentState, conditions, dispatch)
  };

  const actions = {
    changeQuery: (query) => dispatch(actionFor(changeQuery, query)),
    sort: (key) => dispatch(actionFor(sort, key))
  };

  return { effects, actions, conditions };
}

export { createDefaultState, createServicesForContext, reducer };
