import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';
import { colors } from '../palettes';
import { flex } from './utility-styles';
import { useDomainModel } from '../model/ModelContext';

const useStyles = makeStyles((theme) => ({
  search: {
    marginLeft: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    backgroundColor: colors.tailwind.gray[200],
    marginRight: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      left: 0,
      position: 'relative',
      marginLeft: theme.spacing(2)
    }
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    ...flex.row('center', 'center')
  },
  inputRoot: {
    color: 'inherit'
  },
  inputInput: {
    '&:focus': {
      boxShadow: `0 0 0 3px ${colors.custom.blue5.transparent[600]}`,
      backgroundColor: colors.white
    },
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1.5, 2, 1.5, 2),
    transition: theme.transitions.create('width'),
    width: '100%',

    [theme.breakpoints.up('sm')]: {
      width: '40ch'
    },
    [theme.breakpoints.down('sm')]: {
      width: '30ch'
    },
    [theme.breakpoints.down('xs')]: {
      width: '16ch'
    }
  }
}));

function QueryInput() {
  const classes = useStyles();
  const { state, services } = useDomainModel();

  function updateQuery(event) {
    services.actions.changeQuery(event.target.value);
  }

  function handleKeyPress(event) {
    if (event.key === 'Enter') {
      services.effects.search();
    }
  }

  return (
    <div className={classes.search}>
      <InputBase
        placeholder="Search…"
        classes={{
          root: classes.inputRoot,
          input: classes.inputInput
        }}
        value={state.query}
        onChange={updateQuery}
        onKeyPress={handleKeyPress}
        type="search"
        inputProps={{ 'aria-label': 'search' }}
      />
    </div>
  );
}

export default QueryInput;
