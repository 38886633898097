/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect } from 'react';
import { createBrowserHistory } from 'history';
import qs from 'qs';
import { makeStyles, ThemeProvider } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import theme from '../theme';
import AppBar from './AppBar';
import NoQuery from './NoQuery';
import NoResults from './NoResults';
import SearchResults from './SearchResults';
import { Content, FixedBar, ScreenHeightFlexColumn } from './Layouts';
import SortOptionsAndResultsStatus from './SearchOptionsAndResultStats';
import { colors } from '../palettes';
import { DomainModelProvider, useDomainModel } from '../model/ModelContext';

const history = createBrowserHistory();

const useStyles = makeStyles(() => ({
  divider: {
    backgroundColor: colors.tailwind.gray[400]
  }
}));

function checkForQueryInUrl() {
  const { location } = history;
  if (!location || !location.search) return null;

  return qs.parse(location.search, { ignoreQueryPrefix: true }).q;
}

function SortOptionsBar() {
  const { services } = useDomainModel();
  if (!services.conditions.hasSearchResults()) return null;

  return (
    <FixedBar>
      <SortOptionsAndResultsStatus />
    </FixedBar>
  );
}

function ContentSwitch() {
  const { services } = useDomainModel();

  if (services.conditions.hasEmptySearchResults()) return <NoResults />;

  if (!services.conditions.hasQuery()) return <NoQuery />;

  return <SearchResults />;
}

function useOnMountCheckForQueryInUrl(services) {
  useEffect(() => {
    const query = checkForQueryInUrl();
    if (query) {
      services.effects.search(query);
    }
  }, []);
}

function useUpdateUrlForNewQuery(state, services) {
  useEffect(() => {
    if (state.loading || services.conditions.hasSearchResults()) {
      history.push(`/?${qs.stringify({q: state.query})}`);
    }
    else history.push('/');
  }, [state.loading, services.conditions.hasSearchResults()]);
}

function MainScreen() {
  const classes = useStyles();
  const { state, services } = useDomainModel();

  useOnMountCheckForQueryInUrl(services);
  useUpdateUrlForNewQuery(state, services);

  return (
    <ThemeProvider theme={theme}>
      <ScreenHeightFlexColumn>
        <FixedBar>
          <AppBar />
        </FixedBar>
        <SortOptionsBar />
        <Divider classes={{ root: classes.divider }} />
        <Content>
          <ContentSwitch />
        </Content>
      </ScreenHeightFlexColumn>
      ;
    </ThemeProvider>
  );
}

function Bootstrap() {
  return (
    <DomainModelProvider>
      <MainScreen />
    </DomainModelProvider>
  );
}

export default Bootstrap;
