import React from 'react';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import MessageWithImage from './MessageWithImage';

function NoResults() {
  return (
    <MessageWithImage src="/empty-box-gray.svg" alt="NoResults">
      <Typography variant="h5" color="textSecondary">
        <Box fontWeight="fontWeightBold">Sorry, there aren&apos;t any results.</Box>
      </Typography>
      <Typography variant="h5" color="textSecondary">
        Maybe a query typo?
      </Typography>
    </MessageWithImage>
  );
}
export default NoResults;
