import axios from 'axios';
import qs from 'qs';
import jsonData from './__tests__/__fixtures__/npms-io-results.json';

function getFixtureData(query, data) {
  return new Promise((resolve) => {
    setTimeout(() => {
      const response = jsonData[query];
      if (response) {
        if (data) resolve({ data: response.details });
        else resolve({ data: response.packages });
      }

      if (data) resolve({ data: {} });
      else resolve({ data: { total: 0, results: [] } });
    }, 600);
  });
}

async function fetch(query, url, data) {
  // return getFixtureData(query, data);

  if (data) return axios.post(url, data);

  return axios.get(url);
}

const SearchService = {
  async search(query, size = 50) {
    const queryParams = { q: query, size };
    const url = `https://api.npms.io/v2/search?${qs.stringify(queryParams)}`;
    const searchResponse = await fetch(query, url);

    const packageResults = searchResponse.data.results;

    if (packageResults.length === 0) return { total: 0, items: [] };

    const packageNames = packageResults.map((result) => result.package.name);

    const packagesUrl = `https://api.npms.io/v2/package/mget`;
    const packagesResponse = await fetch(query, packagesUrl, packageNames);

    const items = packageNames
      .filter((name) => packagesResponse.data[name] && !packagesResponse.data[name].error)
      .map((key, index) => ({
        ...packagesResponse.data[key],
        searchScore: packageResults[index].searchScore,
        gitHubStars: packagesResponse.data[key].collected.github
          ? packagesResponse.data[key].collected.github.starsCount
          : null,
        downloads: packagesResponse.data[key].collected.npm
          ? packagesResponse.data[key].collected.npm.downloads[2].count
          : null
      }));
    return { total: searchResponse.data.total, items };
  }
};

export default SearchService;
